import Image from 'next/image'

import CSS from './impact-figures.module.css'


export const ImpactFiguresGrid = ({ children }) => {
    return (
        <section className={`${CSS.layout} block bg-secondary`}>
            <div className={CSS.graphic}>
                <Image src={`/assets/img/homepage/brand-pattern-horizontal.jpg`} layout={`fill`} width={1920} height={1000} alt="" priority />
            </div>
            { children }
        </section>
    )
}

export const ImpactFigures = ({ children, theme }) => {
    return (
        <section className={CSS.container}>
            <div className={`${CSS.impactFigures} container`}>
                {children}
            </div>
        </section>
    )
}


export const ImpactFigure = ({value, label}) => {
    return (
        <div className={CSS.impactFigure}>
            <p className={`impactFigureValue fs-0 fw-200 c-primary`}>{value}</p>
            <p className={`caps`}>{label}</p>
        </div>
    )
}

export const ImpactFigureSpacer = () => <div className={CSS.spacer}></div>